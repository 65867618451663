html {
  font-size: 62.5%;

  &.sr .load-hidden {
    visibility: hidden;
  }
}

body {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  scroll-behavior: smooth;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: $light-grey;
}
::-webkit-scrollbar-thumb:hover {
  background: $primary-color;
}

.hidden-asset {
  opacity: 0 !important;
  width: 0px !important;
  height: 0px !important;
  font-size: 0px !important;
  position: absolute !important;
  line-height: 0 !important;
  bottom: -2048px;
}

#hero {
  background-color: #ffffffcc !important;
  z-index: 2;
}

#paper {
  z-index: 3;
  background-color: #ffffff;
}

.app-badges {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 0px 14px 0px;
}

.app-badges img {
  height: 46px !important;
  width: auto !important;
  margin-right: 10px;
}

.app-badges a {
  border: 0px;
}

.app-link {
  padding: 0px 0px 20px 0px;
}
.app-link a {
  font-size: 20px !important;
}

.app-product-tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.app-product-tag {
  margin-right: 4px;
  margin-top: 4px;
  padding: 2px 8px;
  border-radius: 4px;
  background-color: grey;
  color: #fff;
}

.counter-section {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9f9;
  flex-direction: row;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 25px;
  margin-bottom: 15px;
  width: 52.375rem;
}

.counter {
  font-size: 4rem;
  font-weight: bold;
  color: #333;
  padding-left: 10px;
  padding-right: 10px;
}

.counter-meta {
  font-size: 2.5rem;
  font-weight: bold;
  color: #33333355;
}

.counter-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

@media (max-width: 41.25rem) {
  .counter {
    font-size: 2rem;
  }

  .counter-meta {
    font-size: 1.6rem;
  }
  .counter-section {
    width: 35.25rem;
  }
}
